<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{ placeHolder }}</span>
    </div>
    <div
      class="caja_entrada"
      :class="
        stateInput == 'inicio'
          ? 'inicio'
          : stateInput == 'onFocus'
          ? 'onFocus'
          : stateInput == 'post'
          ? 'post'
          : stateInput == 'onError'
          ? 'onError'
          : ''
      "
    >
      <input
        :type="typeInputLocal"
        class="inputDinamicInputBox caja_entrada_text"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${placeHolder}${requerido}`"
        v-model="variable"
        @keypress="isRut($event)"
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="
          cambiarType($event);
          evaluarData();
        "
        :ref="rut ? 'rut' : ''"
      />
      <!-- :tabindex="rut? -1 : 0" -->
      <div class="ojo" @click="verOcultar" v-if="typeInput == 'textP'">
        <img
          :src="ojo"
          alt=""
          v-if="typeInput == 'textP' && textoPassVisible"
        />
        <img
          :src="ojoCerrado"
          alt=""
          v-if="typeInput == 'textP' && !textoPassVisible"
        />
      </div>
    </div>
    <div class="mensajesForm">
      <div class="mensajeError" v-show="stateInput == 'onError'">
        <div>{{ variable == "" ? mensajeError[0] : mensajeError[1] }}</div>
        <div class="mensaje_ejemplo" v-show="stateInput == 'onError'">
          {{ txt_ejemplo }}
        </div>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    typeInput: { type: String },
    placeHolder: { type: String },
    requerido: { type: String },
    rut: { type: Boolean },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    borrarDatos: { type: Boolean },
    dataInicial: String, //Simular solo para Simular
    txt_ejemplo: String,
  },
  data() {
    return {
      variable: "",
      typeInputLocal: "text",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      textoPassVisible: false,
      inFocus: false,
    };
  },
  methods: {
    ...mapActions(["validarRut"]),

    isRut(evt) {

      if (this.typeInput == "textP") {
        let key = evt.keyCode;
        if (key === 32) {
          evt.preventDefault();
          return;
        }
      }

      if (this.rut) {
        evt = evt ? evt : window.event;
        let caracter = String.fromCharCode(evt.which);
        let search = /[0-9kK-]/;
        if (caracter.match(search)) {
          // console.log("evaluando");
          return true;
        } else {
          evt.preventDefault();
        }
        if (this.variable == "-") {
          this.variable = "";
        }
      }
    },

    async cambiarType(evt) {
      let evento = evt.type;

      

      if (!this.textoPassVisible) {
        if (this.typeInput == "textP") {
          if (evento == "blur" && this.variable == "") {
            this.typeInputLocal = "text";
          } else if (evento == "focus") {
            if (this.typeInputLocal == "text") {
              this.typeInputLocal = "password";
            }
          }
        } else if (this.typeInput == "rut") {
          this.typeInputLocal = "text";
          if (evento == "blur") {
            this.variable = this.variable.replaceAll(".", "");
            this.variable = this.variable.replaceAll("-", "");
            let numChar = this.variable.length;
            let rut = this.variable.substring(0, numChar - 1);
            let digito = this.variable.substring(numChar - 1);
            this.variable = `${rut}-${digito}`;
            if (this.variable == "-") {
              this.variable = "";
            }
          }
        } else {
          if (this.typeInput != "textP") {
            this.typeInputLocal = this.typeInput;
          }
        }
      }

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.inFocus = false;
        if (this.rut) {
          if (await this.validarRut(this.variable)) {
            this.stateInput = "post";
          } else {
            this.stateInput = "onError";
          }
        } else {
          if (this.error) {
            this.stateInput = "onError";
          } else {
            this.stateInput = "post";
          }
        }
      }

      this.interactuado = true;
    },

    verOcultar() {
      if (this.typeInputLocal == "password") {
        this.typeInputLocal = "text";
        this.textoPassVisible = true;
      } else {
        this.typeInputLocal = "password";
        this.textoPassVisible = false;
      }
    },
    evaluarData() {
      this.$emit("evaluarData", this.variable);
    },
  },
  watch: {
    variable() {
      this.$emit("dispatchChangeData", this.variable.trim());
    },
    error() {
      // if (!this.rut) {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      // }
    },
    borrarDatos() {
      this.variable = "";
    },
  },
  created() {},
  mounted() {
    if (this.typeInput == "textP") {
      setTimeout(() => {
        this.typeInputLocal = "text";
      }, 500);
    } else {
      this.typeInputLocal = this.typeInput;
    }
    

    if (this.dataInicial != "") {
      this.variable = this.dataInicial;
    }
  },
};
</script>

<style >
/* .inputBoxLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.inputInputBox {
  border: none;
  background-color: transparent !important ;
  font-weight: 500 !important;
  line-height: 12.89px !important;
  font-size: 0.6875rem !important;
}

.inputInputBox:focus {
  outline: none;
}
.inputInputBox {
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
}

.inputInputBox:-webkit-autofill,
.inputInputBox:-webkit-autofill:hover,
.inputInputBox:-webkit-autofill:focus,
.inputInputBox:-webkit-autofill:active {
  --webkit-box-shadow: 0 0 0 30px white inset !important;
}

.inputInputBox::placeholder {
  color: var(--customG-787878);
  opacity: 1;
}

:-ms-.inputInputBox-placeholder {
  color: var(--customG-787878);
}

::-ms-.inputInputBox-placeholder {
  color: var(--customG-787878);
}

/* Error y paceholder */
.error.inputInputBox::placeholder {
  color: var(--customA-801B1C);
  opacity: 1;
}

.error.inputInputBox:-ms-input-placeholder {
  color: var(--customA-801B1C);
}

.error.inputInputBox::-ms-input-placeholder {
  color: var(--customA-801B1C);
}

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}
</style>
<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader" ref="myHeader_regisForm">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <div class="header_top">
              <!-- <headerBack @back="backLocal" /> -->
              <headerCerrar @cancelar="cerrarRegistro" colorIcon="rojoOscuro" />
            </div>
          </div>
          <div class="regisFormTitle title">
            {{ t("app.registro.tit") }}
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_regisForm"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        ref="contenido_regisForm"
        class="contenido centrado"
        :style="contenido_regisForm"
        v-show="cont_visible"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="">
            <div class="regisPreInput">
              <inputBox
                typeInput="rut"
                placeHolder="Rut"
                requerido="*"
                :rut="true"
                :error="errorRut"
                @dispatchChangeData="updateRut"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  t('configuracion.data.textos_errores.rut'),
                ]"
                :mensajeOk="{ mostrar: false, texto: '' }"
                dataInicial=""
              />
            </div>

            <div class="regisPreInput">
              <inputBox
                typeInput="email"
                placeHolder="Mail"
                requerido="*"
                :rut="false"
                :error="errorCorreo"
                @dispatchChangeData="updateCorreo"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  t('configuracion.data.textos_errores.correo'),
                ]"
                :mensajeOk="{ mostrar: false, texto: '' }"
                :class="!mensajeOkRutMostrar.mostrar ? 'disabledComp' : ''"
                @evaluarData="evaluarDataCorreo"
                dataInicial=""
              />
            </div>

            <div class="regisPreInput">
              <inputBox
                typeInput="email"
                :placeHolder="t('app.registro.confirmarMail')"
                requerido="*"
                :rut="false"
                :error="errorCorreoConfirm"
                @dispatchChangeData="updateCorreoConfirm"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  t('configuracion.data.textos_errores.emailNoCoincide'),
                ]"
                :mensajeOk="{ mostrar: false, texto: '' }"
                :class="!mensajeOkCorreoMostrar.mostrar ? 'disabledComp' : ''"
                dataInicial=""
                @paste.prevent
              />
            </div>

            <div class="regisPreInput">
              <inputBox
                typeInput="textP"
                :placeHolder="t('app.registro.contrasena')"
                requerido="*"
                :rut="false"
                :error="errorPass"
                @dispatchChangeData="updatePasswordUno"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  'mínimo 6 caracteres',
                ]"
                @blur="passVisitada = true"
                :mensajeOk="{
                  mostrar: mensajeOkPassMostrar,
                  texto: t('app.registro.passOk'),
                }"
                :class="
                  !mensajeOkCorreoConfirmMostrar.mostrar ? 'disabledComp' : ''
                "
                dataInicial=""
                @evaluarData="evaluarData"
              />
            </div>

            <div class="regisPreInput">
              <inputBox
                typeInput="textP"
                :placeHolder="t('app.registro.contrasenaConfirm')"
                requerido="*"
                :rut="false"
                :error="errorPassConfirm"
                @dispatchChangeData="updatePasswordDos"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  '',
                ]"
                :mensajeOk="{
                  mostrar: mensajeOkConfirmPassMostrar,
                  texto: t('app.registro.passConfirmOk'),
                }"
                :class="!mensajeOkPassMostrar ? 'disabledComp' : ''"
                dataInicial=""
                @paste.prevent
              />
            </div>
          </div>

          <div class="recoverPassPreFortalezas"></div>

          <div class="regisFormActionBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.registrarse')"
              :disabled="!btnActive"
              @action="check_data"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
            />
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_regisForm"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_regisForm">
        <div class="">
          <div class="">
            <pasosGiro paso="1" totales="4" />
          </div>
        </div>

        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
// import headerBack from "../components/generales/headerBack.vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import inputBox from "../components/generales/inputBox.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registro",
  components: {
    // headerBack,
    headerCerrar,
    inputBox,
    btnAction,
    pasosGiro,
    enviosSeguros,
    blurComp,
    screen_width_cont,
  },
  data() {
    return {
      errorRut: false,
      rut: "",
      errorCorreo: false,
      errorCorreoConfirm: false,
      correo: "",
      correoConfirm: "",
      errorPass: false,
      errorPassConfirm: false,
      passwordUno: "",
      passwordDos: "",
      errorPassCorta: true,
      passVisitada: false,
      passDebil: false,
      mensajeOkRutMostrar: { mostrar: false, texto: "" },
      mensajeOkCorreoMostrar: { mostrar: false, texto: "" },
      mensajeOkCorreoConfirmMostrar: { mostrar: false, texto: "" },
      mensajeOkPassMostrar: false,
      mensajeOkConfirmPassMostrar: false,
      correoConfirmVisitado: false,
      passwordDosVisitado: false,
      btnActive: true,
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_regisForm: {},
      blur_sup_regisForm: {},
      blur_inf_regisForm: {},
    };
  },
  computed: {
    ...mapState([
      "resp_pre_registro",
      "procesoGeneral",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "url_base_web",
      "idiomaPreferencia",
    ]),

    estadoBtn() {
      let aux = "inactive";
      if (
        this.rut != "" &&
        this.correo != "" &&
        this.correoConfirm != "" &&
        this.passwordUno != "" &&
        this.passwordDos != ""
      ) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateListaElementosDom",
      "updateDesde",
      "updateProcesoGeneral",
      "updateBloquearNavegacion",
      "setshowSpinner",
    ]),
    ...mapActions(["register", "validarString", "signOff",]),

    // backLocal() {
    //   if (this.procesoGeneral == "logIn") {
    //     this.$router.push({ name: "logIn", replace: true });
    //   }
    // },
    cerrarRegistro() {
      console.log(this.plataforma);
      if (this.plataforma == "web") {
        window.location.replace(
          `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
        );
      } else {
        this.$router.push({ name: "logIn", replace: true });
      }
      this.signOff();
    },
    async check_data() {
      let errores = false;

      if (!this.mensajeOkRutMostrar.mostrar) {
        this.errorRut = true;
        errores = true;
      }

      if (!this.mensajeOkCorreoMostrar.mostrar) {
        this.errorCorreo = true;
        errores = true;
      }
      if (this.correoConfirm.length == 0 || this.correoConfirm != this.correo) {
        this.errorCorreoConfirm = true;
        errores = true;
      }
      if (!this.mensajeOkPassMostrar) {
        this.errorPass = true;
        this.passVisitada = true;
        errores = true;
      }
      if (!this.mensajeOkConfirmPassMostrar) {
        this.errorPassConfirm = true;
        errores = true;
      }

      if (errores) {
        this.setshowSpinner(false);
        return;
      }

      this.btnActive = false;
      this.register({
        usuario: this.rut,
        contrasena: this.passwordUno,
        correo: this.correo,
      });
    },

    async updateRut(data) {
      this.rut = data;
      if (data.length < 6) {
        this.mensajeOkRutMostrar.mostrar = false;
      }
      let check_rut = await this.validarString({
        string: data,
        validaciones: ["rut-empresa"],
      });
      if (check_rut.length > 0) {
        this.mensajeOkRutMostrar.mostrar = false;
      } else {
        this.mensajeOkRutMostrar.mostrar = true;
      }
    },
    async updateCorreo(data) {
      this.correo = data;
      let evaluacionData = await this.validarString({
        string: data,
        validaciones: ["notempty", "correo"],
      });
      if (evaluacionData.length == 0) {
        this.mensajeOkCorreoMostrar.mostrar = true;
      } else {
        this.mensajeOkCorreoMostrar.mostrar = false;
      }
    },
    async evaluarDataCorreo(data) {
      let evaluacionData = await this.validarString({
        string: data,
        validaciones: ["notempty", "correo"],
      });
      if (evaluacionData.length == 0) {
        this.errorCorreo = false;
        this.mensajeOkCorreoMostrar.mostrar = true;
      } else {
        this.errorCorreo = true;
        this.mensajeOkCorreoMostrar.mostrar = false;
      }
    },
    evaluarData() {
      if (!this.mensajeOkPassMostrar && this.passVisitada) {
        this.errorPass = true;
        this.passVisitada = true;
      }
      if (!this.mensajeOkConfirmPassMostrar && this.passwordDosVisitado) {
        this.errorPassConfirm = true;
      }
    },
    updateCorreoConfirm(data) {
      this.correoConfirm = data;
      this.correoConfirmVisitado = true;
    },
    updatePasswordUno(data) {
      this.passVisitada = true;
      this.passwordUno = data;
      this.errorPass = false;
    },
    updatePasswordDos(data) {
      this.passwordDosVisitado = true;
      this.passwordDos = data;
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
    async presentAlert(data) {
      let alert = await alertController.create({
        header: data.tit,
        subHeader: data.mensaje,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    resp_pre_registro() {
      if (this.resp_pre_registro.status) {
        this.setshowSpinner(false);
        if (this.resp_pre_registro.resp) {
          this.$router.push({ name: "autentificacionRegistro", replace: true });
        } else {
          console.log("Error", this.resp_pre_registro.msg);
          if (this.resp_pre_registro.msg == "ERROR_CLI_004") {
            this.presentAlert({
              tit: "Error en el registro",
              mensaje: this.t(
                `configuracion.errores_srv.Cliente.${this.resp_pre_registro.msg}`
              ),
            });
          } else if (this.resp_pre_registro.msg == "ERROR_CLI_016") {
            this.presentAlert({
              tit: "Error en el registro",
              mensaje: this.t(
                `configuracion.errores_srv.Cliente.${this.resp_pre_registro.msg}`
              ),
            });
          } else if (this.resp_pre_registro.msg == "ERROR_CLI_014") {
            this.presentAlert({
              tit: "Error en el registro",
              mensaje: this.t(
                `configuracion.errores_srv.Cliente.${this.resp_pre_registro.msg}`
              ),
            });
          }
        }
        this.btnActive = true;
      }
    },
    passwordUno() {
      if (this.passwordUno.length > 5) {
        this.errorPassCorta = false;
      } else {
        this.errorPassCorta = true;
      }

      if (!this.errorPassCorta) {
        this.mensajeOkPassMostrar = true;
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else {
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkPassMostrar = false;
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordDosVisitado) {
        if (this.passwordUno != this.passwordDos) {
          this.errorPassConfirm = true;
        } else {
          this.errorPassConfirm = false;
        }
      }
    },
    passwordDos() {
      if (!this.errorPassCorta) {
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else {
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordUno != this.passwordDos) {
        this.errorPassConfirm = true;
      } else {
        this.errorPassConfirm = false;
      }
    },

    correoConfirm() {
      if (this.correo != this.correoConfirm) {
        this.errorCorreoConfirm = true;
        this.mensajeOkCorreoConfirmMostrar.mostrar = false;
      } else {
        this.errorCorreoConfirm = false;
        this.mensajeOkCorreoConfirmMostrar.mostrar = true;
      }
    },
    correo() {
      if (this.correoConfirmVisitado) {
        if (this.correo != this.correoConfirm) {
          this.errorCorreoConfirm = true;
        } else {
          this.errorCorreoConfirm = false;
        }
      }
    },
    heigthScreen() {
      this.contenido_regisForm = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  mounted() {},
  async ionViewWillEnter() {
    this.updateListaElementosDom([]);
    this.btnActive = true;
    this.rut = "";
    this.correo = "";
    this.passwordUno = "";
    this.passwordDos = "";
  },
  ionViewDidEnter() {
    this.rut = "";
    this.correo = "";
    this.passwordUno = "";
    this.passwordDos = "";

    this.updateDesde("registro");

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_regisForm"].offsetHeight;
        this.footer = this.$refs["footer_regisForm"].offsetHeight;
        this.cont_visible = true;
        this.contenido_regisForm = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_regisForm = blur_sup_style(this.header);
        this.blur_inf_regisForm = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_regisForm"].scrollHeight >
          this.$refs["contenido_regisForm"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    console.log("saliendo de registro");
    this.cont_visible = false;
    this.btnActive = true;
    this.rut = "";
    this.correo = "";
    this.passwordUno = "";
    this.passwordDos = "";

    this.errorRut = false;
    this.errorCorreo = false;
    this.errorCorreoConfirm = false;
    this.correoConfirm = "";
    this.errorPass = false;
    this.errorPassConfirm = false;
    this.errorPassCorta = true;
    this.errorPassMayus = true;
    this.errorPassMinus = true;
    this.errorPassNumber = true;
    this.errorPassSimbol = true;
    this.passVisitada = false;
    this.passDebil = false;
    this.mensajeOkRutMostrar = { mostrar: false, texto: "" };
    this.mensajeOkCorreoMostrar = { mostrar: false, texto: "" };
    this.mensajeOkCorreoConfirmMostrar = { mostrar: false, texto: "" };
    this.mensajeOkPassMostrar = false;
    this.mensajeOkConfirmPassMostrar = false;
    this.correoConfirmVisitado = false;
    this.passwordDosVisitado = false;
  },
};
</script>

<style scoped>
.regisFormTitle {
  margin-top: 1.5rem;
  padding-bottom: 0.3rem;
  color: var(--customA-801B1C);
  text-align: center;
}

.regisPreInput {
  margin-top: 0.7rem;
}

.regisFormConfirmPass {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 20px;
  color: var(--customG-787878);
}

.regisFormConfirmPass span {
  display: inline-block;
  margin-right: 0.8rem;
}

.regisFormActionBtn {
  margin-top: 3rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.inputContainer {
  border: 1px solid rgb(107, 99, 99);
  border-radius: 10px;
  height: fit-content;
}

.labelBasic {
  font-size: 0.6875rem;
  padding-left: 0.6875rem;
  margin-bottom: 0.1rem;
  text-align: left;
}

.tieneError {
  color: red;
}

.recoverPassPreFortalezas {
  margin-top: 1rem;
}
</style>